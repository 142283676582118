import { parse, serialize } from 'cookie';

export function getCookie(name, request) {
	if (request?.headers?.cookie) {
		const cookies = parse(request.headers.cookie || '');
		return cookies[name] || null;
	}

	if (typeof document === 'undefined') {
		return null;
	}

	const cookies = parse(document.cookie || '');
	return cookies[name] || null;
}

export function setCookie(name, value, options = {}) {
	const cookieStr = serialize(name, value, {
		path: '/',
		...options,
	});
	document.cookie = cookieStr;
}

export function deleteCookie(name) {
	setCookie(name, '', { expires: new Date(0) });
}
