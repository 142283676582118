import { browser } from '$app/environment';
import { getCookie, setCookie } from '$lib/cookies';
import { createId } from '@paralleldrive/cuid2';
import { prng_alea } from 'esm-seedrandom';

/**
 * Creates an experiment with the given name and groups, validating that the total percentage does not exceed 1.0 (100%).
 * @param {string} name - The name of the experiment. e.g. 'my-experiment'.
 * @param {Object} groups - An object where keys are group labels and values are distribution percentages. e.g. { 'group-1': 0.5, 'group-2': 0.5 }.
 * @returns {Object} The experiment configuration.
 * @throws Will throw an error if the total percentage exceeds 1.0 (100%).
 */
export function createExperiment({ name, groups }) {
	if (!name || !groups) {
		throw new Error('Experiment name and groups must be provided');
	}

	const totalPercentage = Object.values(groups).reduce(
		(sum, value) => sum + value,
		0,
	);

	if (totalPercentage > 1) {
		throw new Error('Group percentages must not exceed 1.0 (100%)');
	}

	return { name, groups };
}

/**
 * Determines the group based on a seeded random value.
 * @param {Object} params - The parameters for the function.
 * @param {string} params.event - The event object containing the cookies and client address to use for seeding the visitor's random assignment.
 * @param {string} params.experiment.name - The name of the experiment.
 * @param {Object} params.experiment.groups - An object where keys are group labels and values are distribution percentages.
 * @returns {string} The label of the assigned group, prefixed with the experiment name.
 */
export function getGroup({ sessionId, event, experiment }) {
	const seed =
		sessionId ??
		event.cookies.get('indow_sessionId') ??
		event.getClientAddress();
	let random = prng_alea(seed).quick();
	let cumulative = 0;

	for (const [label, percentage] of Object.entries(experiment.groups)) {
		cumulative += percentage;
		if (random < cumulative) {
			return `${experiment.name}_${label}`;
		}
	}

	// If no group is matched, use the remaining percentage for a control/fallback group.
	return `${experiment.name}_control`;
}
export const client = {
	/**
	 * Client side function to assign a group to the current user based on their session ID and experiment configuration.
	 * @param {Object} experimentConfig - The parameters for the function.
	 * @param {Object} experimentConfig.name - The name of the experiment.
	 * @param {Object} experimentConfig.groups - An object where keys are group labels and values are distribution percentages. e.g. { 'group-1': 0.5, 'group-2': 0.5 }.
	 * @returns {string} The label of the assigned group, prefixed with the experiment name.
	 */
	getGroup(experimentConfig) {
		if (!browser) {
			return null;
		}
		let sessionId = getCookie('indow_sessionId');
		if (!sessionId) {
			sessionId = createId();
			setCookie('indow_sessionId', sessionId, {
				maxAge: 1704085200,
				httpOnly: false,
			});
		}

		const experiment = createExperiment(experimentConfig);
		const group = getGroup({ sessionId, experiment });

		if (group != getCookie('indow_experimentGroup')) {
			setCookie('indow_experimentGroup', group, {
				maxAge: 1704085200,
				httpOnly: false,
			});
		}

		return group;
	},
};
